<template>
    <div class="section">
        <page-title :title="$t('sst.pages.baf.title')" class="my-3"></page-title>
        <b-card>
            <b-row>
                <b-col v-if="!refreshData">
                    <div class="site-selectbox">
                        <multiselect
                            v-model="selectedIntervention"
                            id="ajax"
                            :custom-label="customLabel"
                            track-by="id"
                            :placeholder="$t('sst.pages.baf.multiselect.searchPlaceholder')"
                            open-direction="bottom"
                            :options="interventionRequests"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="true"
                            :close-on-select="true"
                            :show-no-results="true"
                            :hide-selected="false"
                            :show-labels="false"
                            @input="change"
                        >
                            <template slot="noResult">
                                <span>
                                    {{ $t('sst.pages.baf.multiselect.noResults') }}
                                </span>
                            </template>
                            <template slot="noOptions">
                                <span>
                                    {{ $t('sst.pages.baf.multiselect.noResults') }}
                                </span>
                            </template>
                        </multiselect>
                    </div>
                </b-col>
                <b-col lg="4" v-if="!refreshData && selectedIntervention">
                    <b-button pill variant="danger" @click="addIntervention">
                        <b-icon icon="plus"></b-icon>
                        {{ $t('sst.pages.baf.buttons.add') }}
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="lg-12">
                    <spinner v-if="refreshData"></spinner>
                    <div v-if="!refreshData">
                        <custom-table
                            v-if="!refreshData"
                            :tableConfig="tableConfig"
                            :fields="fields"
                            :items="items"
                            :page="page"
                        ></custom-table>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import PageTitle from '@/layouts/components/PageTitle.vue';
import Spinner from '@/components/shared/element/Spinner';
import CustomTable from '@/components/shared/table/CustomTable';
import { BafRepository } from '@/repositories';
import { TABLE } from '@/constants';
import eventBus from '@/plugins/eventBus';

export default {
    name: 'SstBafCurrentList',
    components: {
        PageTitle,
        Spinner,
        CustomTable
    },
    data() {
        return {
            selectedIntervention: null,
            interventionRequests: [],
            items: [],
            isHeaderLoading: false,
            fields: [
                {
                    key: 'id',
                    label: this.$t('sst.pages.baf.table.columns.id'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'site.name',
                    label: this.$t('sst.pages.baf.table.columns.site'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'metal_rollout_count',
                    label: this.$t('sst.pages.baf.table.columns.nbMetalRollout'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'ht_amount',
                    label: this.$t('sst.pages.baf.table.columns.price'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'checkbox',
                    label: this.$t('sst.pages.baf.table.columns.quotation'),
                    sortable: false,
                    class: 'text-left'
                },
                {
                    key: 'show_details',
                    label: this.$t('sst.pages.baf.table.columns.actions'),
                    sortable: false,
                    class: 'text-left'
                }
            ],
            tableConfig: {
                sortBy: 'sst_bill_record_date',
                striped: true,
                bordered: true,
                headVariant: 'dark',
                sortDesc: true,
                currentPage: 1,
                displayCount: false,
                displayTotalRows: false,
                displayAddBtn: false
            },
            page: TABLE.PAGE.SST.BAF.ID,
            refreshData: false,
            displayTable: false
        };
    },
    created() {
        this.loadInterventionRequests();
        this.loadCurrentBaf();
        eventBus.$on('reloadSstBaf', tableItemId => {
            this.loadInterventionRequests();
            this.loadCurrentBaf(tableItemId);
        });
    },
    methods: {
        change() {
            this.displayTable = !!this.selectedIntervention;
        },
        loadInterventionRequests() {
            this.interventionRequests = [];
            BafRepository.loadInterventionRequests().then(response => {
                this.interventionRequests = response.data.data;
            });
        },
        loadCurrentBaf(tableItemId = null) {
            this.refreshData = true;
            this.items = [];
            BafRepository.currentBaf().then(response => {
                let element = response.data.data;
                if (element.interventions.length > 0) {
                    element.interventions.forEach(intevention => {
                        this.items.push(intevention);
                    });
                    if (tableItemId) {
                        this.items = this.items.map(intervention => {
                            return {
                                ...intervention,
                                _showDetails: intervention.id === tableItemId
                            };
                        });
                    }
                }
                this.refreshData = false;
                if (element.interventions.length > 0) {
                    this.tableConfig.displayAddBtn = true;
                }
            });
        },
        customLabel({ site }) {
            return site.id + '-' + site.name;
        },
        addIntervention() {
            BafRepository.createIntervention(this.selectedIntervention.id)
                .then(() => {
                    this.loadCurrentBaf();
                    this.loadInterventionRequests();
                    this.$toast.success(
                        this.$t('sst.pages.baf.notifications.add_intervention.success', { id: this.selectedIntervention.id })
                    );
                    this.selectedIntervention = null;
                })
                .catch(() => {
                    this.$toast.error(this.$t('sst.pages.baf.notifications.add_intervention.error'));
                });
        }
    }
};
</script>

<style>
.site-selectbox {
    position: absolute;
    margin-top: 10px;
    z-index: 10000;
    width: 40%;
}
@media screen and (max-width: 799px) {
    .site-selectbox {
        position: relative;
        width: 100%;
        margin-top: 0 !important;
    }
}
@media (max-width: 767.98px) {
    .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
        width: 30%;
    }
}
</style>
